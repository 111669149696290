export const About = () => {
  return (
    <div>
      <section id="about">
        <h2>Sobre Nosotros</h2>
        <p>
          En La Vieja Adventures, nuestra misión es brindarte una experiencia
          inolvidable mientras exploramos juntos las maravillas de Ciudad
          Esmeralda. Contamos con guías expertos y equipo de seguridad de alta
          calidad para garantizar que tu aventura sea no solo emocionante, sino
          también segura y responsable.
        </p>
      </section>
    </div>
  );
};

export default About;
