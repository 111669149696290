import React, { useEffect } from "react";

export const Private = ({ paypalLoaded }) => {
  useEffect(() => {
    if (paypalLoaded && window.paypal) {
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [{ amount: { value: "59.00" } }],
            });
          },
          onApprove: (data, actions) => {
            return actions.order.capture().then((details) => {
              alert(
                "Transaction completed by " + details.payer.name.given_name
              );
            });
          },
        })
        .render("#paypal-container-ZFKB85D82BW26");
    }

    // Clean up PayPal buttons on component unmount
    return () => {
      document.getElementById("paypal-container-ZFKB85D82BW26").innerHTML = "";
    };
  }, [paypalLoaded]);
  return (
    <div>
      <div className="package">
        <h3>Paquete Privado</h3>
        <p>
          Incluye: guía exclusivo, flexibilidad en la ruta, alimentación
          premium, y acceso a instalaciones exclusivas.
        </p>
        <p>
          <strong>Precio:</strong> $59 / 29,990₡
        </p>
      </div>
      <div className="package" id="paypal-container-ZFKB85D82BW26"></div>
    </div>
  );
};

export default Private;
