import React, { useEffect } from "react";

export const Daily = ({ paypalLoaded }) => {
  useEffect(() => {
    if (paypalLoaded && window.paypal) {
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [{ amount: { value: "39.00" } }],
            });
          },
          onApprove: (data, actions) => {
            return actions.order.capture().then((details) => {
              alert(
                "Transaction completed by " + details.payer.name.given_name
              );
            });
          },
        })
        .render("#paypal-container-YWPEQ6APSEK7N");
    }
    // Clean up PayPal buttons on component unmount

    return () => {
      document.getElementById("paypal-container-YWPEQ6APSEK7N").innerHTML = "";
    };
  }, [paypalLoaded]);

  return (
    <div>
      <div className="package">
        <h3>Pase del Día</h3>
        <p>Incluye todo del Básico, almuerzo y acceso extendido.</p>
        <p>
          <strong>Precio:</strong> $39 / 19,990₡
        </p>
      </div>
      <div className="package" id="paypal-container-YWPEQ6APSEK7N"></div>
    </div>
  );
};

export default Daily;
