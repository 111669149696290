export const Footer = () => {
  return (
    <div>
      <footer className="App-footer">
        <p>Ubicación: San Carlos, a la entrada de Ciudad Quesada</p>
        <a
          href="https://goo.gl/maps/ahcSaFXtoXhcX6uu6"
          target="_blank"
          rel="noopener noreferrer"
        >
          Ver en Google Maps
        </a>
      </footer>
    </div>
  );
};

export default Footer;
