import React, { useEffect } from "react";

export const Basic = ({ paypalLoaded }) => {
  useEffect(() => {
    if (paypalLoaded && window.paypal) {
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [{ amount: { value: "29.00" } }],
            });
          },
          onApprove: (data, actions) => {
            return actions.order.capture().then((details) => {
              alert(
                "Transaction completed by " + details.payer.name.given_name
              );
            });
          },
        })
        .render("#paypal-container-9ZUUPLV7BLJDC");
    }
    // Clean up PayPal buttons on component unmount
    return () => {
      document.getElementById("paypal-container-9ZUUPLV7BLJDC").innerHTML = "";
    };
  }, [paypalLoaded]);

  return (
    <div>
      <div className="package">
        <h3>Paquete Básico</h3>
        <p>
          Incluye: Guía local, equipo de seguridad, acceso a senderos y
          vestidores.
        </p>
        <p>
          <strong>Precio:</strong> $29 / 14,990₡
        </p>
      </div>
      <div className="package" id="paypal-container-9ZUUPLV7BLJDC"></div>
    </div>
  );
};

export default Basic;
