import logo from "../logo1.jpg";

export const Header = () => {
  return (
    <div>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="Ciudad Esmeralda Logo" />
        <h1>Bienvenidos a Ciudad Esmeralda</h1>
        <p>
          Explora la naturaleza como nunca antes en el majestuoso Cañón del Río
          La Vieja.
          <br></br>
          Vive una aventura única, segura y llena de emociones en el corazón de
          la naturaleza.
        </p>
        <div className="App-buttons">
          <a className="App-link" href="#packages" rel="noopener noreferrer">
            Nuestros Paquetes
          </a>
          <a className="App-link" href="#about" rel="noopener noreferrer">
            Reservar Ahora
          </a>
        </div>
      </header>
    </div>
  );
};

export default Header;
