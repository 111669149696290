import Basic from "./packages/Basic";
import Daily from "./packages/Daily";
import Private from "./packages/Private";
import React, { useEffect, useState } from "react";

export const Packages = () => {
  const [paypalLoaded, setPaypalLoaded] = useState(false);

  useEffect(() => {
    const scriptId = "paypal-script";
    if (!document.getElementById(scriptId)) {
      const script = document.createElement("script");
      script.src =
        "https://www.paypal.com/sdk/js?client-id=ATWVG0nWjjjNy0zN9fTbfynX2ndyINIaH9f1J35Tyf3Qj_CxB_eVuGp7dbeEziyiesCp4-YOyG9CWNWy&components=buttons&disable-funding=venmo&currency=USD";
      script.async = true;
      script.crossOrigin = "anonymous";
      script.id = scriptId;
      script.onload = () => setPaypalLoaded(true);
      document.body.appendChild(script);
    } else {
      setPaypalLoaded(true);
    }
  }, []);

  return (
    <div>
      <section id="packages" className="packages">
        <Basic paypalLoaded={paypalLoaded} />
        <Daily paypalLoaded={paypalLoaded} />
        <Private paypalLoaded={paypalLoaded} />
      </section>
    </div>
  );
};

export default Packages;
